.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
  }
  
  .modal {
    position: fixed;
    top: 10vh;
    left: 10%;
    width: 600px;
    z-index: 100;
    overflow: hidden;
  }
  
  .header {
    background: #2FA4E7;
    padding: 1.3rem;
  }
  
  .header h2 {
    margin: 0;
    color: white;
  }
  
  .content {
    padding: 1rem;
  }
  
  .actions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
  }
  
  @media (min-width: 1824px) {
    .modal {
      left: calc(30% + 100px);
      width: 600px;
    }
  }

  @media (max-width: 1224px) {
    .modal {
      left: calc(50% - 20rem);
      width: 40rem;
    }
  }